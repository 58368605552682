var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Core } from './core';
var TextAnimationDirective = /** @class */ (function (_super) {
    __extends(TextAnimationDirective, _super);
    function TextAnimationDirective(texts) {
        if (texts === void 0) { texts = ['Front-end', 'Angular', 'Wordpress']; }
        var _this = _super.call(this) || this;
        _this.texts = texts;
        _this.textAnimation = _this.texts;
        _this.tagName = 'text-animation';
        _this.period = 5000;
        _this.loopNum = 0;
        _this.letter = '';
        _this.displayOnlyFirstOne = true;
        /**
         * Pass to true when the animation of a text end;
         */
        _this.animationEnd = false;
        return _this;
    }
    TextAnimationDirective.prototype.init = function () {
        this.getElement(this.tagName);
        this.startAnimation();
    };
    TextAnimationDirective.prototype.startAnimation = function () {
        this.tick();
        this.isDeleting = false;
    };
    TextAnimationDirective.prototype.setStrings = function (texts) {
        this.textAnimation = texts;
    };
    TextAnimationDirective.prototype.reset = function () {
        this.period = 5000;
        this.loopNum = 0;
        this.isDeleting = false;
        this.letter = '';
        this.displayOnlyFirstOne = true;
        if (!!this.timeout) {
            clearTimeout(this.timeout);
        }
    };
    TextAnimationDirective.prototype.tick = function () {
        var _this = this;
        var i = this.loopNum % this.textAnimation.length;
        var fullTxt = this.textAnimation[i];
        if (this.isDeleting) {
            this.letter = fullTxt.substring(0, this.letter.length - 1);
        }
        else {
            this.letter = fullTxt.substring(0, this.letter.length + 1);
        }
        this.nativeElement.innerHTML = "<span class=\"wrap\">".concat(this.letter, "</span>");
        var delta = 300 - Math.random() * 100;
        if (this.isDeleting) {
            delta /= 2;
        }
        if (!this.isDeleting && this.letter === fullTxt) {
            delta = this.period;
            this.isDeleting = true;
            this.animationEnd = true;
            if (this.displayOnlyFirstOne) {
                return;
            }
        }
        else if (this.isDeleting && this.letter === '') {
            this.isDeleting = false;
            this.animationEnd = false;
            this.loopNum++;
            delta = 500;
        }
        var timeout = setTimeout(function () {
            _this.tick();
            clearTimeout(timeout);
        }, delta);
        this.timeout = timeout;
    };
    return TextAnimationDirective;
}(Core));
export { TextAnimationDirective };
