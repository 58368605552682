import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { forEach, upperFirst } from 'lodash';
import './main.less';
import { Core } from './ts/core';
import { TextAnimationDirective } from './ts/text-animation-directive';
import { timelines } from './ts/timelines';
var core = new Core();
var textAnimation = new TextAnimationDirective();
function resetTextAnimation(texts) {
    textAnimation.setStrings(texts);
    textAnimation.reset();
    textAnimation.init();
}
gsap.registerPlugin(ScrollTrigger);
if (history.scrollRestoration) {
    history.scrollRestoration = 'manual';
}
window.onload = function () {
    timelines.loading(textAnimation);
    timelines.header.start();
    forEach(core.getElements('.drop-effect'), function (el) {
        el.addEventListener('mouseenter', function () {
            resetTextAnimation(new Array(upperFirst(el.getAttribute('name'))));
        });
        el.addEventListener('mouseleave', function () {
            return null;
        });
    });
};
