import { first } from 'lodash';
var Core = /** @class */ (function () {
    function Core() {
    }
    Object.defineProperty(Core.prototype, "nativeElement", {
        get: function () {
            return first(this.elements);
        },
        enumerable: false,
        configurable: true
    });
    ;
    Core.prototype.getDomElement = function (attributeName) {
        var elements;
        if (/^\./.test(attributeName)) {
            return document.getElementsByClassName(attributeName.replace(/\./, ''));
        }
        elements = document.querySelectorAll("[".concat(attributeName, "]"));
        if (!elements || (elements === null || elements === void 0 ? void 0 : elements.length) <= 0) {
            elements = document.getElementsByTagName(attributeName);
        }
        if (!elements) {
            throw new Error('Element is undefined');
        }
        return elements;
    };
    Core.prototype.getElements = function (attributeName) {
        this.elements = this.getDomElement(attributeName);
        return this.elements;
    };
    Core.prototype.getElement = function (attributeName) {
        this.elements = this.getDomElement(attributeName);
        return this.nativeElement;
    };
    return Core;
}());
export { Core };
