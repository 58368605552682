import { gsap } from 'gsap';
export var loadingTimeline = function (textanimation) { return gsap.timeline()
    .to('.loading-overlay', {
    filter: 'blur(200px)',
    borderRadius: '50%',
    delay: 1,
})
    .to('.loading-overlay', {
    opacity: 0,
    onComplete: function () {
        textanimation.init();
    },
}); };
